import { User } from '@supabase/auth-js';
import { supabase } from './supabase-config';

let CURRENT_USER: User | undefined = undefined;

export const getCurrentUser_synchronous = () => CURRENT_USER;

export const getSignedInUser = async () => {
    const sessionResponse = await supabase.auth.getSession();

    // cache most recent response for synchronous access in logging calls
    CURRENT_USER = sessionResponse.data.session?.user;

    if (!sessionResponse) {
        return null;
    }

    return sessionResponse.data.session?.user;
};
