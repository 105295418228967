import { useMemo, useState } from 'react';
import { Button, Form, Input, DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import { inviteUserToTeam, updateAthlete } from '@supa/users';
import { Athlete, TEAM_ROLE } from '@type/db-types';
import { log } from '@utils/logging';
import { useTeams } from '@providers/TeamProvider';
import { format } from 'date-fns';
import { stringFromError } from '@utils/utils';

interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
    gender: string;
    weight: string;
    birthday: string;
    // FIXME: discuss other options:
    // girls with girls only (wrestleWith)
    // address
    // years wrestled
    // parent info
    // emergencyContactName/emergencyContactNumber
    // phoneNumber
    // codeOfConduct acknowledgement
    // school name?
}
export interface NewAthleteFormValues extends FormValues {
    role: TEAM_ROLE.ATHLETE;
    selectedTeamId: string;
}

interface Props {
    onSuccess: () => void;
    editingUser?: Athlete | boolean | undefined;
}
const AddWrestlerForm = ({ onSuccess, editingUser }: Props) => {
    // FIXME: link up common user editing fields with `AddCoachesForm` and `AddWrestlerForm`
    const isExistingUser = !!(editingUser as Athlete)?.id;
    const existingUser = useMemo(
        () => (isExistingUser ? (editingUser as Athlete) : ({} as Athlete)),
        [editingUser, isExistingUser],
    );
    const initialValues = useMemo(
        () =>
            isExistingUser
                ? {
                      ...existingUser,
                      //  NOTE: dayjs does NOT correctly parse the date from the database, use date-fns...
                      birthday: dayjs(
                          format((editingUser as Athlete).birthday, 'MM/dd/yyyy'),
                      ) as unknown as string, // FIXME: remove typecasting once we unify date-pickers into MaterialUI
                  }
                : {
                      // FIXME: review this logic
                      paymentStatus: 'failed',
                  },
        [editingUser, existingUser, isExistingUser],
    );
    const [form] = Form.useForm();
    const { selectedTeam } = useTeams();

    const [loading, setLoading] = useState(false);

    const onSubmit = async (values: FormValues) => {
        setLoading(true);
        if (editingUser) {
            await updateAthlete({
                ...existingUser,
                ...values,
                weight: Number(values.weight),
            }).catch((e) => log(`[AddWrestlerForm:update] ${stringFromError(e)}`));
            // .finally(() => setLoading(false));
        } else {
            await inviteUserToTeam({
                ...values,
                role: TEAM_ROLE.ATHLETE,
                selectedTeamId: selectedTeam?.id || '',
            })
                .catch((e) => log(`[AddWrestlerForm] ${e}`))
                .finally(() => setLoading(false));
        }
        setLoading(false);
        onSuccess();
    };

    const handleValueOnChange = () => {
        // const value = form.getFieldValue('gender');
        // setGender(value);
    };

    return (
        <>
            <div className="form-add-wrestler-container">
                <Form
                    name="addWrestlerForm"
                    onFinish={onSubmit}
                    autoComplete="off"
                    layout={'vertical'}
                    initialValues={initialValues}
                    form={form}
                >
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your first name!',
                                    },
                                ]}
                            >
                                <Input size="large" placeholder="Enter First Name" />
                            </Form.Item>
                        </div>
                        <div className="col-md-6">
                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Last name!',
                                    },
                                ]}
                            >
                                <Input size="large" placeholder="Enter Last Name" />
                            </Form.Item>
                        </div>
                        <div className="col-md-6">
                            <Form.Item
                                label="Gender"
                                name="gender"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select your gender!',
                                    },
                                ]}
                            >
                                <Select placeholder="Select Gender" onChange={handleValueOnChange}>
                                    <Select.Option value="male">Male</Select.Option>
                                    <Select.Option value="female">Female</Select.Option>
                                    <Select.Option value="other">Other</Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-md-6">
                            <Form.Item
                                label="Date of birth"
                                name="birthday"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select your date of birth!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    showToday={false}
                                    placeholder="Select Date"
                                    format={'MM/DD/YYYY'}
                                    disabledDate={(current) =>
                                        current && current > dayjs().endOf('day')
                                    }
                                    allowClear
                                />
                            </Form.Item>
                        </div>
                        <div className="col-md-6">
                            <Form.Item
                                label="Weight"
                                name="weight"
                                help="Add your weight in Pound (lb)s"
                                rules={[
                                    {
                                        pattern: /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/,
                                        message: 'Please enter correct weight',
                                        required: false,
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your weight',
                                    },
                                ]}
                            >
                                <Input size="large" placeholder="Wrestler Weight" />
                            </Form.Item>
                        </div>
                        <div className="col-md-6">
                            <Form.Item
                                label="Email id"
                                name="email"
                                tooltip="Parents email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your email',
                                    },
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder="Enter Email"
                                    disabled={!!editingUser}
                                />
                            </Form.Item>
                        </div>

                        <Form.Item
                            name="teamStatus"
                            label="Membership status"
                            style={{ marginTop: '1rem' }}
                        >
                            <Select placeholder="Select Option">
                                <Select.Option value="invited">Invited</Select.Option>
                                <Select.Option value="active">Active member</Select.Option>
                                <Select.Option value="declined">Declined Invite</Select.Option>
                                <Select.Option value="left">Left team</Select.Option>
                                <Select.Option value="removed">Removed from team</Select.Option>
                                <Select.Option value="cancelled">Invite cancelled</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="paymentStatus" label="Fee status">
                            <Select placeholder="Select Option">
                                <Select.Option value="succeeded">
                                    Success (Payment Completed)
                                </Select.Option>
                                <Select.Option value="failed">
                                    Failure (Payment Not Completed)
                                </Select.Option>
                                <Select.Option value="not-required">
                                    Payment Not Required
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        {/* <div className="col-md-6">
                             // FIXME: this is for new wresler registration
                            <Form.Item
                                label="Grade"
                                name="grade"
                                rules={[
                                    {
                                        message: 'Please enter grade',
                                        required: false,
                                    },
                                ]}
                            >
                                <Input size="large" placeholder="Enter Grade" />
                            </Form.Item>
                        </div> */}
                        {/* <div className="col-md-6">
                             // FIXME: this is for new wresler registration
                            <Form.Item
                                label="Years Wrestled (int)"
                                name="yearsWrestled"
                                rules={[
                                    {
                                        message: 'Please enter years wrestled',
                                        required: false,
                                    },
                                ]}
                            >
                                <InputNumber size="large" placeholder="Enter Years Wrestled" />
                            </Form.Item>
                        </div>

                        <div className="col-md-6">
                            <Form.Item
                                label="Parent’s Name"
                                name="parentName"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your parent's name",
                                    },
                                ]}
                            >
                                <Input size="large" placeholder="Enter Parent's Name" />
                            </Form.Item>
                        </div>

                        <div className="col-md-6">
                            <Form.Item
                                label="Phone Number"
                                name="phoneNumber"
                                rules={[
                                    {
                                        message: 'Please enter phone number',
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber size="large" placeholder="Enter Phone Number" />
                            </Form.Item>
                        </div>

                        <div className="col-12">
                            <Form.Item
                                label="Address"
                                name="address"
                                rules={[
                                    {
                                        message: 'Please enter address',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input.TextArea rows={3} size="large" placeholder="Enter Address" />
                            </Form.Item>
                        </div>

                        <div className="col-md-6">
                            <Form.Item
                                label="Emergency Contact Name"
                                name="emergencyContactName"
                                rules={[
                                    {
                                        message: 'Please enter emergency contact name',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input size="large" placeholder="Enter Emergency Contact Name" />
                            </Form.Item>
                        </div>

                        <div className="col-md-6">
                            <Form.Item
                                label="Emergency Contact Number"
                                name="emergencyContactNumber"
                                rules={[
                                    {
                                        message: 'Please enter emergency contact number',
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber
                                    size="large"
                                    placeholder="Enter Emergency Contact Number"
                                />
                            </Form.Item>
                        </div> */}
                        {/* <div className="col-md-6">
                             // FIXME: this is for new wresler registration

                                    <Form.Item
                                        name="codeOfConduct"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please accept code of conduct!',
                                            },
                                        ]}
                                    >
                                        <Checkbox>
                                            Accept{' '}
                                            <Button
                                                type="link"
                                                className="p-0"
                                                onClick={() => setShowTermsModal(true)}
                                            >
                                                code of conduct
                                            </Button>
                                        </Checkbox>
                                    </Form.Item>
                                </div> */}
                    </div>
                    <div className="col-12">
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="w-100 btn-primary rounded-pill mt-3"
                                loading={loading}
                            >
                                {
                                    // registerWrestlerItSelf
                                    //     ? 'Join'
                                    //     :
                                    isExistingUser ? 'Update wrestler' : 'Add wrestler'
                                }
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>

            {/* <Modal
            // FIXME: this is for new wresler registration
                title={<span className="custom-heading">Code of Conduct</span>}
                open={showTermsModal}
                footer={false}
                onCancel={() => setShowTermsModal(false)}
            >
                <div className="terms-modal">
                    {settingData && (
                        <div dangerouslySetInnerHTML={{ __html: settingData.codeOfConduct }}></div>
                    )}
                </div>
            </Modal> */}
        </>
    );
};

export default AddWrestlerForm;
