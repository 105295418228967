/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

import pkgInfo from '../../package.json';
import { APP_ENV, isProduction } from '@modules/config';
import { User } from '../types/app-types';

const SENTRY_ENABLED = process.env.NODE_ENV === 'production'; // enable on deployed builds, not localhost

console.log(`SENTRY_ENABLED: ${SENTRY_ENABLED}`);

const RATE = isProduction ? 0.1 : 1.0;

export function initializeSentry() {
    Sentry.init({
        dsn: 'https://ebcd57ecb651598722eb1a3b397db20d@o4508003277930496.ingest.us.sentry.io/4508008841412608',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
        ],
        release: 'process.env.VITE_SENTRY_RELEASE -- FIXME:',
        enabled: SENTRY_ENABLED,
        environment: APP_ENV,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: RATE, // production can consume ~1k transactions/day (limit is 10k/month)

        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: RATE, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    // set package version as a tag
    Sentry.setTag('version', pkgInfo.version);
}

export function log(message: string, data?: any) {
    if (data) {
        breadcrumb(message, 'auto-breadcrumb', data);
    }
    if (SENTRY_ENABLED) {
        Sentry.captureMessage(message);
    } else {
        console.warn(message, data);
    }
}

export function logError(error: Error) {
    if (SENTRY_ENABLED) {
        Sentry.captureException(error);
    } else {
        console.error(error);
    }
}
export function setSentryUser(user: User | null) {
    if (SENTRY_ENABLED) {
        const sentryUser: Sentry.User | null = user
            ? // @ts-expect-error FIXME: when we integrate with supabase
              { displayName: user?.displayName, email: user?.email || '', id: user?.uid }
            : null;
        Sentry.setUser(sentryUser);
    }
}

export function breadcrumb(message: string, category: string, crumb?: any) {
    if (SENTRY_ENABLED) {
        Sentry.addBreadcrumb({
            category: category,
            message: message,
            data: crumb,
        });
    } else {
        console.info(`BREADCRUMB: ${message}: ${JSON.stringify(crumb)}`);
    }
}
