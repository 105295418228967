import { Button, Form, Input } from 'antd';
import { useTeams } from '@providers/TeamProvider';
import { inviteUserToTeam } from '@supa/users';
import { TEAM_ROLE, TeamMember } from '@type/db-types';
import { log } from '@utils/logging';
import { stringFromError } from '@utils/utils';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { useMemo } from 'react';

interface Props {
    onSuccess: () => void;
    editingUser?: TeamMember | boolean | undefined;
}
// FIXME: unify with other user invite forms and switch to formik
interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
}
export interface NewCoachFormValues extends FormValues {
    role: TEAM_ROLE;
    selectedTeamId: string;
}
const AddCoachesForm = ({ editingUser, onSuccess }: Props) => {
    // FIXME: link up common user editing fields with `AddCoachesForm` and `AddWrestlerForm`
    const isExistingUser = !!(editingUser as TeamMember)?.id;
    const initialValues = useMemo(
        () => (isExistingUser ? (editingUser as TeamMember) : undefined),
        [editingUser, isExistingUser],
    );
    const { loading, selectedTeam } = useTeams();

    const onFinish = (values: FormValues) => {
        invariant(selectedTeam, 'Team must be selected to add a coach');

        inviteUserToTeam({ ...values, role: TEAM_ROLE.COACH, selectedTeamId: selectedTeam.id })
            .then(onSuccess)
            .catch((e) => {
                log(`[AddCoachesForm] ${stringFromError(e)}`);
                toast.error('Failed to add coach');
            });
    };

    return (
        <>
            <div className="form-add-coach-container">
                <Form
                    name="addCoachesForm"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout={'vertical'}
                    initialValues={initialValues}
                >
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your first name!',
                                    },
                                ]}
                            >
                                <Input size="large" placeholder="Enter First Name" />
                            </Form.Item>
                        </div>

                        <div className="col-md-6">
                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Last name!',
                                    },
                                ]}
                            >
                                <Input size="large" placeholder="Enter Last Name" />
                            </Form.Item>
                        </div>

                        <div className="col-12">
                            <Form.Item
                                label="Email Id"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!',
                                    },
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder="Enter Email"
                                    disabled={!!editingUser}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-12  mt-3">
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="w-100 btn-primary rounded-pill"
                                loading={loading}
                            >
                                {isExistingUser ? 'Update Coach info' : 'Add coach'}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default AddCoachesForm;
