import { Database } from './database.types';

export enum TEAM_ROLE {
    ADMIN = 'admin-coach',
    COACH = 'coach',
    ATHLETE = 'athlete',
    PARENT = 'parent',
}
export enum STATUS {
    INVITED = 'invited',
    ACTIVE = 'active',
    DECLINED = 'declined',
    LEFT = 'left',
    REMOVED = 'removed',
    CANCELLED = 'cancelled',
    PENDING = 'pending',
}

export const COACH_ROLE = [TEAM_ROLE.ADMIN, TEAM_ROLE.COACH];

export type User = Database['public']['Tables']['users']['Row'];
export type UserMembership = Database['public']['Tables']['user_team']['Row'];
export type DbAthlete = Database['public']['Tables']['athletes']['Row'];
export interface Athlete extends TeamMember, Pick<DbAthlete, 'birthday' | 'gender' | 'weight'> {}

// overriding types for TeamMember because postgres' erroneous null typing
// https://github.com/orgs/supabase/discussions/14151#discussioncomment-7162821
type DbTeamMember = Database['public']['Views']['TeamMembership']['Row'];

export interface TeamMember extends DbTeamMember {
    email: string;
    firstName: string;
    id: string;
    imageUrl: string;
    lastName: string;
    membershipId: string;
    phone: string;
    role: TEAM_ROLE;
    teamId: string;
    teamStatus: STATUS;
    userCreatedAt: string;
    userId: string;
    userStatus: STATUS;
    userUpdatedAt: string;
}
