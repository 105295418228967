import { createClient } from '@supabase/supabase-js';
import { Database } from '@type/database.types';

const supabaseUrl: string = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const API_URL = `${supabaseUrl}/functions/v1/api`;

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
    auth: {
        autoRefreshToken: true,
        persistSession: true,
        detectSessionInUrl: true, // disable?
    },
});

// Tells Supabase Auth to continuously refresh the session automatically
// if the app is in the foreground. When this is added, you will continue
// to receive `onAuthStateChange` events with the `TOKEN_REFRESHED` or
// `SIGNED_OUT` event if the user's session is terminated. This should
// only be registered once.
export const supabaseStartAutoRefresh = () => supabase.auth.startAutoRefresh();

export const supabaseStopAutoRefresh = () => supabase.auth.stopAutoRefresh();

export async function signOut() {
    const { error } = await supabase.auth.signOut();
    console.log(`logged out`, { error });
}
