import { Session } from '@supabase/supabase-js';
import { supabase, supabaseStartAutoRefresh, supabaseStopAutoRefresh } from '@supa/supabase-config';
import { useEffect, useMemo, useState } from 'react';
import { breadcrumb, logError } from '@utils/logging';
import { AuthContext, AuthContextValues } from '@hooks/useAuth';

export default function AuthProvider({
    children,
}: {
    children: React.JSX.Element;
}): React.JSX.Element {
    const [session, setSession] = useState<Session | null | undefined>();

    useEffect(() => {
        supabaseStartAutoRefresh();
        return () => {
            supabaseStopAutoRefresh();
        };
    }, []);

    useEffect(() => {
        supabase.auth
            .getSession()
            .then(({ data: { session } }) => {
                setSession(session);
                console.log(`[AuthProvider] session started`, session);
                // console.log(`****** Access Token:`, session?.access_token);
            })
            .catch((error) => {
                breadcrumb('auth', 'error', {
                    error: error?.message,
                });
                if (error instanceof Error) logError(error);
                setSession(null);
            });

        const result = supabase.auth.onAuthStateChange((_event, newSession) => {
            console.log(`[onAuthStateChange] setting auth session`, { newSession });

            setSession(newSession);
        });
        return () => result.data.subscription.unsubscribe();
    }, []);

    const authContext: AuthContextValues = useMemo(() => {
        return {
            authUser: session?.user,
            loading: session === undefined,
            loggedIn: !!session?.user.id,
            session,
        };
    }, [session]);

    return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
}
