import pkgInfo from '../../package.json';

export const apiEndpoint = 'https://dev-api.boutstream.com/';

export const APP_ENV = import.meta.env.VITE_APP_ENV;
export const API_URL = import.meta.env.VITE_API_URL;

export const isProduction = APP_ENV === 'production';

export const VERSION = pkgInfo.version;

console.log(`App ENV: ${APP_ENV}`, {
    APP_ENV,
    isProduction,
    NODE_ENV: process.env.NODE_ENV,
    API_URL,
});
